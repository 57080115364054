function fetchConfig (url, setConfig, keycloak) {
    const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Auth-Token': url.includes('staging') ? keycloak.tokenParsed?.api_token_staging : keycloak.tokenParsed?.api_token
        }
    }

    fetch(url, options).then(response => {
        console.log(`Status code recieved: ${response.status}`)
        const res = response.json()
        return res
    }).then(data => {
        console.log('Successfully fetched config')
        setConfig(data)
    }).catch(error => {
        console.log(`Error received: ${error}`)
    })
}

export default fetchConfig
