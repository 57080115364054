import React from 'react'
import { Button } from 'react-bootstrap'
import { useKeycloak } from '@react-keycloak/web'

function SendTriple (props) {
    const { keycloak } = useKeycloak()

    function handleClick () {
        console.log('Sending to database')
        fetchTriple()
    }

    function getURL () {
        if (props.db === 'Live Database') {
            return 'https://api.intuety.io/aurora/api/elasticdb/otg/ingest/'
        } else if (props.db === 'Staging Database') {
            return 'https://api.staging.intuety.io/aurora/api/elasticdb/otg/ingest/'
        }
    }

    function buildQuery () {
        const query = {
            Activity: props.activity,
            Risk: props.risk,
            Mitigation: props.mitigation
        }
        return JSON.stringify(query)
    }

    function fetchTriple () {
        const options = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Auth-Token': props.db === 'Staging Database' ? keycloak.tokenParsed?.api_token_staging : keycloak.tokenParsed?.api_token
            },
            body: buildQuery(props)
        }
        fetch(getURL(), options).then(response => {
            console.log(`status code received: ${response.status}`)
            return response.json()
        }).then(() => {
            props.setShowModal(true)
        }).catch(error => {
            console.log(`Error received: ${error}`)
        })
    }

    if (props.getValidTriple()) {
        return (
            <Button variant='outline-success' onClick={handleClick}> Add Triple </Button>
        )
    } else {
        return (
            <Button variant='outline-success' onClick={handleClick} disabled> Add Triple </Button>
        )
    }
}

export default SendTriple
