import React, { useEffect, useState } from 'react'
import { LineChart, Line, Bar, BarChart, Cell, ResponsiveContainer, Tooltip, Legend, XAxis, YAxis, CartesianGrid } from 'recharts'
import styled from 'styled-components'
import BarModal from './BarModal'
import fetchJobs from './fetchInProgressJobs'
import { Col, Dropdown, Row } from 'react-bootstrap'
import { sortDay, sortMonth, sortWeek, sortYear, sortLast30 } from './sortData'
import RecommendationsTile from './RecommendationsTile'
import MetricTile from './MetricTile'
import MetricPieChart from './MetricPieChart'
import { getClient } from '../utils'
import ClientDropdown from './ClientDropdown'
import { useKeycloak } from '@react-keycloak/web'

const OptionsHolder = styled.div`
    display: flex;
    justify-content: space-between;
    min-width: 95%;
    padding: 15px
`

function Dashboard () {
    const [inProgressJobs, setInProgressJobs] = useState([])
    const [inProgressJobCount, setInProgressJobCount] = useState(null)
    const [completedJobs, setCompletedJobs] = useState([])
    const [completedJobCount, setCompletedJobCount] = useState(null)
    const [failedJobs, setFailedJobs] = useState([])
    const [failedData, setFailedData] = useState([])
    const [failedJobCount, setFailedJobCount] = useState(null)
    const [clientIdCount, setClientdIdCount] = useState(null)
    const [mostClientIdCount, setMostActiveClient] = useState([])
    const [jobs, setJobs] = useState([])
    const [barData, setBarData] = useState(false)
    const [activeItem, setActiveItem] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [dropDownOpen, setDropDownOpen] = useState(false)
    const [currentGraph, setCurrentGraph] = useState('Today')
    const [timeToProcessCount, setTimeToProcessCount] = useState([])
    const [averageProcessTime, setAverageProcessTime] = useState(null)
    const [currentClients, setCurrentClients] = useState([])
    const { keycloak } = useKeycloak()

    const dropdownToggle = () => setDropDownOpen(prevState => !prevState)

    const refetchJobs = () => {
        fetchJobs('in-progress', setInProgressJobs, setInProgressJobCount, currentGraph, currentClients, keycloak)
        fetchJobs('completed', setCompletedJobs, setCompletedJobCount, currentGraph, currentClients, keycloak)
        fetchJobs('failed', setFailedJobs, setFailedJobCount, currentGraph, currentClients, keycloak)
    }

    useEffect(() => {
        refetchJobs()
    }, [currentClients])

    const setMostClientIdCount = async (clientId) => {
        const res = await getClient(keycloak.tokenParsed?.internal_services_token, clientId)
        setMostActiveClient(res.clientName)
    }

    const getTodaysJobsArray = () => {
        const tmp = [...Array(24)].map(() => [])
        const timearray = [].map(() => [])
        const count = []
        let avg = 0
        let mostActive = -1
        let timedif

        for (const job of inProgressJobs) {
            if (!isNaN(job._source.Client_ID)) {
                count[job._source.Client_ID] = count[job._source.Client_ID] ? count[job._source.Client_ID] + 1 : 1
            }

            mostActive = -1

            for (const [key, value] of Object.entries(count)) {
                if (mostActive === -1 || value > count[mostActive]) {
                    mostActive = key
                }
            }

            const date = new Date(job._source.created_at)
            const update = new Date(job._source.Last_Update)

            if (!isNaN(date.getTime())) {
                timedif = update.getTime() - date.getTime()
                const pos = date.getHours()
                // avg = avg + timedif
                timedif = Math.round(timedif / 60000)
                tmp[pos].push(job)
                // timearray.push(timedif)
            }
        }

        for (const job of completedJobs) {
            if (job._source.Client_ID > 0) {
                count[job._source.Client_ID] = count[job._source.Client_ID] ? count[job._source.Client_ID] + 1 : 1
            }

            mostActive = -1

            for (const [key, value] of Object.entries(count)) {
                if (mostActive === -1 || value > count[mostActive]) {
                    mostActive = key
                }
            }

            const date = new Date(job._source.created_at)
            const update = new Date(job._source.Last_Update)

            if (!isNaN(date.getTime())) {
                timedif = update.getTime() - date.getTime()
                const pos = date.getHours()
                avg = avg + timedif
                timedif = Math.round(timedif / 60000)
                tmp[pos].push(job)
                timearray.push(timedif)
            }
        }

        const failedtmp = [...Array(24)].map(() => [])

        for (const job of failedJobs) {
            if (job._source.Client_ID > 0) {
                count.push(job._source.Client_ID)
            }

            const date = new Date(job._source.created_at)

            if (!isNaN(date.getTime())) {
                const pos = date.getHours()
                failedtmp[pos].push(job)
            }
        }

        const timecount = []
        const uniqueChars = [...new Set(count)]

        for (let i = 0; i < 60; i++) {
            timecount[i] = timearray.filter(x => { return x === i }).length
        }

        setJobs(tmp)
        setFailedData(failedtmp)
        setMostClientIdCount(mostActive)
        setClientdIdCount(uniqueChars.length)
        setTimeToProcessCount(timecount)
        setAverageProcessTime(avg / timearray.length)
    }

    const getYesterdaysJobsArray = () => {
        const tmp = [...Array(24)].map(() => [])
        const count = []
        const timearray = []
        let avg = 0
        let mostActive = -1
        let timedif

        for (const job of inProgressJobs) {
            if (job._source.Client_ID > 0) {
                count[job._source.Client_ID] = count[job._source.Client_ID] ? count[job._source.Client_ID] + 1 : 1
            }

            mostActive = -1

            for (const [key, value] of Object.entries(count)) {
                if (mostActive === -1 || value > count[mostActive]) {
                    mostActive = key
                }
            }

            const date = new Date(job._source.created_at)
            const update = new Date(job._source.Last_Update)

            if (!isNaN(date.getTime())) {
                const pos = date.getHours()
                timedif = update.getTime() - date.getTime()
                // avg = avg + timedif
                timedif = Math.round(timedif / 60000)
                // timearray.push(timedif)
                tmp[pos].push(job)
            }
        }

        for (const job of completedJobs) {
            if (job._source.Client_ID > 0) {
                count[job._source.Client_ID] = count[job._source.Client_ID] ? count[job._source.Client_ID] + 1 : 1
            }

            mostActive = -1

            for (const [key, value] of Object.entries(count)) {
                if (mostActive === -1 || value > count[mostActive]) {
                    mostActive = key
                }
            }

            const date = new Date(job._source.created_at)
            const update = new Date(job._source.Last_Update)

            if (!isNaN(date.getTime())) {
                const pos = date.getHours()
                let timedif = update.getTime() - date.getTime()
                avg = avg + timedif
                timedif = Math.round(timedif / 60000)
                timearray.push(timedif)
                tmp[pos].push(job)
            }
        }

        const failedtmp = [...Array(24)].map(() => [])

        for (const job of failedJobs) {
            if (job._source.Client_ID > 0) {
                count.push(job._source.Client_ID)
            }

            const date = new Date(job._source.created_at)

            if (!isNaN(date.getTime())) {
                const pos = date.getHours()
                failedtmp[pos].push(job)
            }
        }

        const timecount = []
        const uniqueChars = [...new Set(count)]

        for (let i = 0; i < 60; i++) {
            timecount[i] = timearray.filter(x => { return x === i }).length
        }

        setJobs(tmp)
        setFailedData(failedtmp)
        setMostClientIdCount(mostActive)
        setClientdIdCount(uniqueChars.length)
        setTimeToProcessCount(timecount)
        setAverageProcessTime(avg / timearray.length)
    }

    const get7DaysJobsArray = () => {
        const tmp = [...Array(7)].map(() => [])
        const timearray = [].map(() => [])
        const count = []
        let avg = 0
        const today = new Date()
        const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 8)
        let mostActive = -1
        let timedif

        for (const job of inProgressJobs) {
            if (job._source.Client_ID > 0) {
                count[job._source.Client_ID] = count[job._source.Client_ID] ? count[job._source.Client_ID] + 1 : 1
            }

            mostActive = -1

            for (const [key, value] of Object.entries(count)) {
                if (mostActive === -1 || value > count[mostActive]) {
                    mostActive = key
                }
            }

            const date = new Date(job._source.created_at)
            const update = new Date(job._source.Last_Update)
            const pos = date.getDay()

            if (!isNaN(date.getDate())) {
                if (date.getDate() !== today.getDate() || date.getDate() !== lastWeek.getDate()) {
                    timedif = update.getTime() - date.getTime()
                    // avg = avg + timedif
                    timedif = Math.round(timedif / 60000)
                    // timearray.push(timedif)
                    tmp[pos].push(job)
                }

                tmp[pos].push(job)
            }
        }

        for (const job of completedJobs) {
            if (job._source.Client_ID > 0) {
                count[job._source.Client_ID] = count[job._source.Client_ID] ? count[job._source.Client_ID] + 1 : 1
            }

            mostActive = -1

            for (const [key, value] of Object.entries(count)) {
                if (mostActive === -1 || value > count[mostActive]) {
                    mostActive = key
                }
            }

            const date = new Date(job._source.created_at)
            const update = new Date(job._source.Last_Update)
            const pos = date.getDay()

            if (!isNaN(date.getDate())) {
                if (date.getDate() !== today.getDate() || date.getDate() !== lastWeek.getDate()) {
                    timedif = update.getTime() - date.getTime()
                    avg = avg + timedif
                    timedif = Math.round(timedif / 60000)
                    timearray.push(timedif)
                    tmp[pos].push(job)
                }
            }
        }

        const failedtmp = [...Array(7)].map(() => [])

        for (const job of failedJobs) {
            if (job._source.Client_ID > 0) {
                count.push(job._source.Client_ID)
            }

            const date = new Date(job._source.created_at)
            const pos = date.getDay()

            if (!isNaN(date.getDate())) {
                if (date.getDate() !== today.getDate() || date.getDate() !== lastWeek.getDate()) {
                    failedtmp[pos].push(job)
                }

                failedtmp[pos].push(job)
            }
        }

        const timecount = []
        const uniqueChars = [...new Set(count)]

        for (let i = 0; i < 60; i++) {
            timecount[i] = timearray.filter(x => { return x === i }).length
        }

        setJobs(tmp)
        setFailedData(failedtmp)
        setMostClientIdCount(mostActive)
        setClientdIdCount(uniqueChars.length)
        setTimeToProcessCount(timecount)
        setAverageProcessTime(avg / timearray.length)
    }

    const get30DaysJobsArray = () => {
        const count = []
        let avg = 0
        const thirtyMonths = [8, 3, 5, 10]
        const tmp = [...Array(30)].map(() => [])
        const timearray = []
        const thirtydays = new Date()
        thirtydays.setHours(0, 0, 0, 0)
        thirtydays.setDate(thirtydays.getDate() - 30)
        const currentDate = new Date()
        let mostActive = -1

        for (const job of inProgressJobs) {
            if (job._source.Client_ID > 0) {
                count[job._source.Client_ID] = count[job._source.Client_ID] ? count[job._source.Client_ID] + 1 : 1
            }

            mostActive = -1

            for (const [key, value] of Object.entries(count)) {
                if (mostActive === -1 || value > count[mostActive]) {
                    mostActive = key
                }
            }

            const date = new Date(job._source.created_at)
            const update = new Date(job._source.Last_Update)

            if (!isNaN(date.getDay())) {
                let mod
                thirtyMonths.includes(currentDate.getMonth() - 1) ? mod = 30 : mod = 31
                const pos = (30 - (currentDate.getDate() - date.getDate())) % mod
                let timedif = update.getTime() - date.getTime()
                // avg = avg + timedif
                timedif = Math.round(timedif / 60000)
                tmp[pos].push(job)
                // timearray.push(timedif)
            }
        }

        for (const job of completedJobs) {
            if (job._source.Client_ID > 0) {
                count[job._source.Client_ID] = count[job._source.Client_ID] ? count[job._source.Client_ID] + 1 : 1
            }

            mostActive = -1

            for (const [key, value] of Object.entries(count)) {
                if (mostActive === -1 || value > count[mostActive]) {
                    mostActive = key
                }
            }

            const date = new Date(job._source.created_at)
            const update = new Date(job._source.Last_Update)
            let mod

            if (!isNaN(date.getDay())) {
                thirtyMonths.includes(currentDate.getMonth() - 1) ? mod = 30 : mod = 31
                const pos = (30 - (currentDate.getDate() - date.getDate())) % mod
                let timedif = update.getTime() - date.getTime()
                avg = avg + timedif
                timedif = Math.round(timedif / 60000)
                tmp[pos].push(job)
                timearray.push(timedif)
            }
        }

        const failedtmp = [...Array(30)].map(() => [])

        for (const job of failedJobs) {
            if (job._source.Client_ID > 0) {
                count.push(job._source.Client_ID)
            }

            const date = new Date(job._source.created_at)
            let mod

            if (!isNaN(date.getDay())) {
                thirtyMonths.includes(currentDate.getMonth() - 1) ? mod = 30 : mod = 31
                const pos = (30 - (currentDate.getDate() - date.getDate())) % mod
                failedtmp[pos].push(job)
            }
        }

        const timecount = []
        const uniqueChars = [...new Set(count)]

        for (let i = 0; i < 60; i++) {
            timecount[i] = timearray.filter(x => { return x === i }).length
        }

        setJobs(tmp)
        setFailedData(failedtmp)
        setMostClientIdCount(mostActive)
        setClientdIdCount(uniqueChars.length)
        setTimeToProcessCount(timecount)
        setAverageProcessTime(avg / timearray.length)
    }

    const getMonthJobsArray = () => {
        let avg = 0
        const count = []
        const currentDate = new Date()
        let size
        let mostActive = -1

        if (currentGraph === 'This Month') {
            size = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate()
        } else {
            size = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate()
        }

        const tmp = [...Array(size)].map(() => [])
        const timearray = []

        for (const job of inProgressJobs) {
            if (job._source.Client_ID > 0) {
                count[job._source.Client_ID] = count[job._source.Client_ID] ? count[job._source.Client_ID] + 1 : 1
            }

            mostActive = -1

            for (const [key, value] of Object.entries(count)) {
                if (mostActive === -1 || value > count[mostActive]) {
                    mostActive = key
                }
            }

            const date = new Date(job._source.created_at)
            const update = new Date(job._source.Last_Update)

            if (!isNaN(date.getDay())) {
                const pos = date.getDate() - 1
                let timedif = update.getTime() - date.getTime()
                timedif = Math.round(timedif / 60000)
                tmp[pos].push(job)
            }
        }

        for (const job of completedJobs) {
            if (job._source.Client_ID > 0) {
                count[job._source.Client_ID] = count[job._source.Client_ID] ? count[job._source.Client_ID] + 1 : 1
            }

            mostActive = -1

            for (const [key, value] of Object.entries(count)) {
                if (mostActive === -1 || value > count[mostActive]) {
                    mostActive = key
                }
            }

            const date = new Date(job._source.created_at)
            const update = new Date(job._source.Last_Update)

            if (!isNaN(date.getDay())) {
                const pos = date.getDate() - 1
                let timedif = update.getTime() - date.getTime()
                avg = avg + timedif
                timedif = Math.round(timedif / 60000)
                tmp[pos].push(job)
                timearray.push(timedif)
            }
        }

        const failedtmp = [...Array(31)].map(() => [])

        for (const job of failedJobs) {
            if (job._source.Client_ID > 0) {
                count.push(job._source.Client_ID)
            }

            const date = new Date(job._source.created_at)

            if (!isNaN(date.getDay())) {
                const pos = date.getDate() - 1
                failedtmp[pos].push(job)
            }
        }

        const timecount = []
        const uniqueChars = [...new Set(count)]

        for (let i = 0; i < 60; i++) {
            timecount[i] = timearray.filter(x => { return x === i }).length
        }

        setJobs(tmp)
        setFailedData(failedtmp)
        setMostClientIdCount(mostActive)
        setClientdIdCount(uniqueChars.length)
        setTimeToProcessCount(timecount)
        setAverageProcessTime(avg / timearray.length)
    }

    const getYearsJobsArray = () => {
        const tmp = [...Array(12)].map(() => [])
        const count = []
        const timearray = []
        let avg = 0
        let mostActive = -1

        for (const job of inProgressJobs) {
            if (job._source.Client_ID > 0) {
                count[job._source.Client_ID] = count[job._source.Client_ID] ? count[job._source.Client_ID] + 1 : 1
            }

            mostActive = -1

            for (const [key, value] of Object.entries(count)) {
                if (mostActive === -1 || value > count[mostActive]) {
                    mostActive = key
                }
            }

            const date = new Date(job._source.created_at)
            const update = new Date(job._source.Last_Update)

            if (!isNaN(date.getMonth())) {
                const pos = date.getMonth()
                let timedif = update.getTime() - date.getTime()
                // avg = avg + timedif
                timedif = Math.round(timedif / 60000)
                tmp[pos].push(job)
                // timearray.push(timedif)
            }
        }

        for (const job of completedJobs) {
            if (job._source.Client_ID > 0) {
                count[job._source.Client_ID] = count[job._source.Client_ID] ? count[job._source.Client_ID] + 1 : 1
            }

            mostActive = -1

            for (const [key, value] of Object.entries(count)) {
                if (mostActive === -1 || value > count[mostActive]) {
                    mostActive = key
                }
            }

            const date = new Date(job._source.created_at)
            const update = new Date(job._source.Last_Update)

            if (!isNaN(date.getMonth())) {
                const pos = date.getMonth()
                let timedif = update.getTime() - date.getTime()
                avg = avg + timedif
                timedif = Math.round(timedif / 60000)
                tmp[pos].push(job)
                timearray.push(timedif)
            }
        }

        const failedtmp = [...Array(12)].map(() => [])

        for (const job of failedJobs) {
            if (job._source.Client_ID > 0) {
                count.push(job._source.Client_ID)
            }

            const date = new Date(job._source.created_at)

            if (!isNaN(date.getMonth())) {
                const pos = date.getMonth()
                failedtmp[pos].push(job)
            }
        }

        const timecount = []
        const uniqueChars = [...new Set(count)]

        for (let i = 0; i < 60; i++) {
            timecount[i] = timearray.filter(x => { return x === i }).length
        }

        setJobs(tmp)
        setFailedData(failedtmp)
        setMostClientIdCount(mostActive)
        setClientdIdCount(uniqueChars.length)
        setTimeToProcessCount(timecount)
        setAverageProcessTime(avg / timearray.length)
    }

    useEffect(() => {
        refetchJobs()
        const poll = setInterval(() => {
            refetchJobs()
        }, 1000 * 60 * 10)

        return () => {
            setInProgressJobs([])
            setCompletedJobs([])
            setFailedJobs([])
            setMostClientIdCount([])
            clearInterval(poll)
        }
    }, [currentGraph])

    useEffect(() => {
        switch (currentGraph) {
        case 'Today':
            getTodaysJobsArray()
            break
        case 'Yesterday':
            getYesterdaysJobsArray()
            break
        case 'Last 7 Days':
            get7DaysJobsArray()
            break
        case 'Last 30 Days':
            get30DaysJobsArray()
            break
        case 'This Month':
            getMonthJobsArray()
            break
        case 'Last Month':
            getMonthJobsArray()
            break
        case 'This Year':
            getYearsJobsArray()
            break
        case 'Last Year':
            getYearsJobsArray()
            break

        default:
            alert('currentGraph not recognised')
        }
    }, [inProgressJobs,
        completedJobs,
        failedJobs,
        currentGraph])

    useEffect(() => {
        // day = 24, week = 7, month = 31, year = 12
        if (jobs.length > 0 && failedData.length > 0) {
            let data = []

            if (currentGraph === 'Today') {
                data = sortDay(jobs, failedData)
            } else if (currentGraph === 'Yesterday') {
                data = sortDay(jobs, failedData)
            } else if (currentGraph === 'Last 7 Days') {
                data = sortWeek(jobs, failedData)
            } else if (currentGraph === 'Last 30 Days') {
                data = sortLast30(jobs, failedData)
            } else if (currentGraph === 'This Month') {
                data = sortMonth(jobs, failedData, new Date().getMonth() + 1)
            } else if (currentGraph === 'Last Month') {
                data = sortMonth(jobs, failedData, new Date().getMonth())
            } else if (currentGraph === 'This Year' || currentGraph.includes('Year')) {
                data = sortYear(jobs, failedData)
            }

            setBarData(data)
        }
    }, [jobs, currentGraph, failedData])

    const handleBarClick = (data, index) => {
        setActiveItem(barData[index])
        setShowModal(true)
    }

    // Sample data
    const [pdata, setpdata] = useState([
        { name: 'inProgressJobs', count: inProgressJobCount, fill: '#8C43A2', border: '#D8D8D8', margin: { top: 20, right: 106, left: 120 } },
        { name: 'totalDocs', count: completedJobCount + failedJobCount, fill: '#D8D8D8', border: '#D8D8D8' }
    ])
    const [cdata, setcdata] = useState([
        { name: 'Completed Jobs', count: completedJobCount, fill: '#2ABF8E80', border: '#D8D8D8', margin: { top: 20, right: 106, left: 120 } },
        { name: 'totalDocs', count: inProgressJobCount + failedJobCount, fill: '#D8D8D8', border: '#D8D8D8' }
    ])

    const [fdata, setfdata] = useState([
        { name: 'Failed Jobs', count: failedJobCount, fill: '#FA8E95', border: '#D8D8D8', margin: { top: 20, right: 106, left: 120 } },
        { name: 'totalDocs', count: inProgressJobCount + completedJobCount, fill: '#D8D8D8', border: '#D8D8D8' }
    ])

    useEffect(() => {
        let isNoJobs = false

        // if their are no jobs, the pie charts don't show so make it 1
        if (inProgressJobCount + completedJobCount + failedJobCount === 0) {
            isNoJobs = true
        }

        setpdata([
            { name: 'inProgressJobs', count: inProgressJobCount, fill: '#8C43A2', border: '#D8D8D8', margin: { top: 20, right: 106, left: 120 } },
            { name: 'totalDocs', count: isNoJobs ? 1 : completedJobCount + failedJobCount, fill: '#D8D8D8', border: '#D8D8D8' }
        ])
        setcdata([
            { name: 'Completed Jobs', count: completedJobCount, fill: '#2ABF8E80', border: '#D8D8D8', margin: { top: 20, right: 106, left: 120 } },
            { name: 'totalDocs', count: isNoJobs ? 1 : inProgressJobCount + failedJobCount, fill: '#D8D8D8', border: '#D8D8D8' }
        ])
        setfdata([
            { name: 'Failed Jobs', count: failedJobCount, fill: '#FA8E95', border: '#D8D8D8', margin: { top: 20, right: 106, left: 120 } },
            { name: 'totalDocs', count: isNoJobs ? 1 : inProgressJobCount + completedJobCount, fill: '#D8D8D8', border: '#D8D8D8' }
        ])
    }, [inProgressJobCount, completedJobCount, failedJobCount])

    const timedata = []
    timeToProcessCount.map((count, minutes) => { return timedata.push({ name: minutes, count: count }) })
    return (
        <>
            <OptionsHolder>
                <Dropdown isOpen={dropDownOpen} toggle={dropdownToggle}>
                    <Dropdown.Toggle caret>
                        {currentGraph}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item header>Select date</Dropdown.Item>
                        <Dropdown.Item onClick={() => { setCurrentGraph('Today') }}>Today</Dropdown.Item>
                        <Dropdown.Item onClick={() => { setCurrentGraph('Yesterday') }}>Yesterday</Dropdown.Item>
                        <Dropdown.Item onClick={() => { setCurrentGraph('Last 7 Days') }}>Last 7 Days</Dropdown.Item>
                        <Dropdown.Item onClick={() => { setCurrentGraph('Last 30 Days') }}>Last 30 Days</Dropdown.Item>
                        <Dropdown.Item onClick={() => { setCurrentGraph('This Month') }}>This Month</Dropdown.Item>
                        <Dropdown.Item onClick={() => { setCurrentGraph('Last Month') }}>Last Month</Dropdown.Item>
                        <Dropdown.Item onClick={() => { setCurrentGraph('This Year') }}>This Year</Dropdown.Item>
                        <Dropdown.Item onClick={() => { setCurrentGraph('Last Year') }}>Last Year</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <ClientDropdown currentClients={currentClients} setCurrentClients={setCurrentClients} />
            </OptionsHolder>

            <Row style={{ justifyContent: 'space-around', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
                <MetricPieChart data={pdata} count={inProgressJobCount} title="In Progress Jobs" />
                <MetricPieChart data={cdata} count={completedJobCount} title="Completed Jobs" />
                <MetricPieChart data={fdata} count={failedJobCount} title="Failed Jobs" />
            </Row>

            <Row>

                {showModal && <BarModal show={showModal} setShow={setShowModal} items={activeItem} />}

                {barData
                    ? <div style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#FFFFFF', border: '1px solid #E5E5E6' }} >
                        <p>Click each rectangle</p>
                        <ResponsiveContainer width="100%" height={300} >
                            <BarChart width={500} height={1000} data={barData} >
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip> </Tooltip>
                                <Bar dataKey="count" onClick={handleBarClick}>
                                    {barData.map((entry, index) => (
                                        <Cell cursor="pointer" fill="#2ABF8E80" key={`cell-${index}`} />
                                    ))}
                                </Bar>
                                <Bar dataKey="failedcount" onClick={handleBarClick}>
                                    {barData.map((entry, index) => (
                                        <Cell cursor="pointer" fill="#F0131380" key={`cell-${index}`} />
                                    ))}
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                        <p className="content">{currentGraph}</p>
                    </div>
                    : null}

            </Row>
            <Row style={{ justifyContent: 'space-around' }}>
                <Col>
                    <Row style={{ justifyContent: 'space-around' }}>
                        <RecommendationsTile timeLimit={currentGraph} status="accepted" jobs={jobs} />
                        <RecommendationsTile timeLimit={currentGraph} status="rejected" jobs={jobs} />
                    </Row>
                    <Row style={{ marginTop: '25px', justifyContent: 'space-around' }}>
                        <MetricTile title={'Number of Active Clients: '} value={clientIdCount} />
                        <MetricTile title={'Most Active Client: '} value={mostClientIdCount} />
                        <MetricTile title={'Average Processing Time (minutes): '} value={(averageProcessTime / 60000).toFixed(1)} />
                    </Row>
                </Col>

                <Col>
                    <Row style={{ justifyContent: 'space-around' }}>
                        <div style={{ paddingRight: '15px', backgroundColor: '#FFFFFF', border: '1px solid #E5E5E6' }}>
                            <p>Processing Times (minutes)</p>
                            <LineChart
                                width={600}
                                height={275}
                                data={timedata} >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip label="minutes" />
                                <Legend />
                                <Line type="monotone" dataKey="count" stroke="#82ca9d" />
                            </LineChart>

                        </div>
                    </Row >
                </Col>
            </Row>
        </>
    )
}

export default Dashboard
