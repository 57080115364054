import React, { createContext, useEffect, useState } from 'react'
import fetchConfig from '../Config/FetchConfig'
import { useKeycloak } from '@react-keycloak/web'


export const ConfigContext = createContext()

export const ConfigProvider = ({ children }) => {
    const [config, setConfig] = useState({})
    const { keycloak } = useKeycloak()

    useEffect(() => {
        fetchConfig('https://api.intuety.io/aurora/api/configdb/fetch', setConfig, keycloak)
    }, [])

    return (
        <ConfigContext.Provider value={config}>
            { config &&
                children
            }
        </ConfigContext.Provider>
    )
}

export default ConfigProvider