import React, { useState } from 'react'
import { Button, Container, Form, Row, Tabs, Tab, Col, Dropdown, DropdownButton } from 'react-bootstrap'
import { useKeycloak } from '@react-keycloak/web'
import './TestDocuments.css'
import { ViewAllClientsButton } from '../utils'

function TestDocuments () {
    const [environment, setEnvironment] = useState('Staging')
    const [riskAssessment, setRiskAssessment] = useState(null)
    const [jobId, setJobId] = useState(null)
    const [status, setStatus] = useState(null)
    const [recommendations, setRecommendations] = useState(null)
    const [safetyBriefings, setSafetyBriefings] = useState(null)
    const [extracted, setExtracted] = useState(null)
    const [sending, setSending] = useState(false)
    const [sent, setSent] = useState(false)
    const [clientId, setClientId] = useState('')
    const [showClients, setShowClients] = useState(false)
    const { keycloak } = useKeycloak()

    function onFileUpload () {
        setRiskAssessment(document.getElementById('ra-upload').files[0])
    }

    function getSubdomain () {
        if (environment === 'Production') {
            return 'api'
        } else {
            return 'api.staging'
        }
    }

    function onFileUploaded (response) {
        clear()
        const id = JSON.parse(response).jobId
        setJobId(id)
        setSending(false)
        setSent(true)
        setTimeout(checkStatus, 1000, id)
    }

    async function fetchSafetyBriefings (id) {
        console.log('Fetching Safety Briefings', id)
        const body = {
            job_id: id
        }

        const response = await fetch(`https://${getSubdomain()}.intuety.io/aurora/api/safety-briefing/fetch-recommendations`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'auth-token': environment === 'Staging' ? keycloak.tokenParsed?.api_token_staging : keycloak.tokenParsed?.api_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })

        const data = await response.json()
        setSafetyBriefings(data.recommended_briefings)
    }

    async function fetchRecommendations (id) {

        const response = await fetch(`https://${getSubdomain()}.intuety.io/intelligence-platform/v1/recommendation/${id}/consolidated`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'auth-token': environment === 'Staging' ? keycloak.tokenParsed?.api_token_staging : keycloak.tokenParsed?.api_token,
                'Content-Type': 'application/json'
            }
        })

        const data = await response.json()
        console.log('recommendations', data)
        setRecommendations(data.recommendations.Recommended_triplets)
        setExtracted(data.extracted)
    }

    async function checkStatus (id) {
        console.log('fetching status', id)
        const body = {
            job_id: id
        }
        const response = await fetch(`https://${getSubdomain()}.intuety.io/aurora/api/status/fetch`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'auth-token': environment === 'Staging' ? keycloak.tokenParsed?.api_token_staging : keycloak.tokenParsed?.api_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })

        if (response.status === 404) {
            alert('Job id not found')
        } else {
            const data = await response.json()
            console.log('status', data)

            if (data.statusCode === 400) {
                alert('Invalid Job ID')
            } else {
                setStatus(data)

                if (data[0]._source.Status === 1) {
                    setTimeout(checkStatus, 4000, id)
                } else if (data[0]._source.Status === 2) {
                    fetchRecommendations(id)
                    fetchSafetyBriefings(id)
                } else if (data[0]._source.Status === 0) {
                    alert('failed')
                }
            }
        }
    }

    function onCheckStatus () {
        clear()
        checkStatus(jobId)
        setSent(true)
    }

    function uploadRiskAssessment () {
        if (riskAssessment) {
            console.log('Uploading risk assessment:', riskAssessment)
            setSending(true)
            const data = new FormData()
            data.append('riskAssessment', riskAssessment)

            if (!Number.isNaN(parseInt(clientId))) {
                data.append('clientId', clientId)
            } else if (clientId.length > 0) {
                alert('Client Id must be an integer')
            }

            const request = new XMLHttpRequest()

            request.onreadystatechange = function () {
                if (request.readyState === 4) {
                    onFileUploaded(request.response)
                }
            }

            console.log('sending risk assessment')

            request.open('POST', `https://${getSubdomain()}.intuety.io/aurora/api/submission/rams`)
            request.setRequestHeader('auth-token', environment === 'Staging' ? keycloak.tokenParsed?.api_token_staging : keycloak.tokenParsed?.api_token)
            request.send(data)
        } else {
            alert('No risk assessment')
        }
    }

    function clear () {
        setStatus(null)
        setRecommendations(null)
        setSafetyBriefings(null)
    }

    function displayJobId () {
        if (sending) {
            return <p>Sending...</p>
        } else if (sent && jobId) {
            return <p>Job ID: {jobId}</p>
        }
    }

    function displayStatus () {
        if (status) {
            return (
                <Tab title="Status" eventKey="status">
                    <div className="TabContent">
                        <table>
                            <thead>
                                <tr>
                                    <th>Time</th>
                                    <th>Status</th>
                                    <th>Remarks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {status.map((log, index) => {
                                    const time = new Date(log._source.Timestamp)
                                    const hours = time.getHours() < 10 ? `0${time.getHours()}` : time.getHours()
                                    const minutes = time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes()
                                    const seconds = time.getSeconds() < 10 ? `0${time.getSeconds()}` : time.getSeconds()
                                    return (
                                        <tr key={index}>
                                            <td>{hours}:{minutes}:{seconds}</td>
                                            <td>{log._source.Status}</td>
                                            <td>{log._source.Remarks}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </Tab>
            )
        } else {
            return (
                <Tab title="Status" disabled></Tab>
            )
        }
    }

    function displayExtracted () {
        if (extracted) {
            return (
                <Tab title="Extracted" eventKey="extracted">
                    <div className="TabContent">
                        <table>
                            <thead>
                                <tr>
                                    <th colSpan="7">Triples found (OTG)</th>
                                </tr>
                                <tr>
                                    <th>Activity</th>
                                    <th>Risk</th>
                                    <th>Mitigation</th>
                                    <th>Pre-Likelihood</th>
                                    <th>Pre-Severity</th>
                                    <th>Post-Likelihood</th>
                                    <th>Post-Severity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {extracted.Triplets_found_in_OTGDb.map((triple, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{triple.Activity}</td>
                                            <td>{triple.Risk}</td>
                                            <td>{triple.Mitigation}</td>
                                            <td>{triple.Risk_Factors ? triple.Risk_Factors.Pre_Mitigation ? triple.Risk_Factors.Pre_Mitigation.Likelihood : 'N/A' : 'N/A'}</td>
                                            <td>{triple.Risk_Factors ? triple.Risk_Factors.Pre_Mitigation ? triple.Risk_Factors.Pre_Mitigation.Severity : 'N/A' : 'N/A'}</td>
                                            <td>{triple.Risk_Factors ? triple.Risk_Factors.Post_Mitigation ? triple.Risk_Factors.Post_Mitigation.Likelihood : 'N/A' : 'N/A'}</td>
                                            <td>{triple.Risk_Factors ? triple.Risk_Factors.Post_Mitigation ? triple.Risk_Factors.Post_Mitigation.Severity : 'N/A' : 'N/A'}</td>
                                        </tr>
                                    )
                                })}
                                <tr><td colSpan="7" style={{ height: '1.2em' }}> </td></tr>
                            </tbody>

                            <thead>
                                <tr>
                                    <th colSpan="7">Triples found (Not in OTG)</th>
                                </tr>
                                <tr>
                                    <th>Activity</th>
                                    <th>Risk</th>
                                    <th>Mitigation</th>
                                    <th>Pre-Likelihood</th>
                                    <th>Pre-Severity</th>
                                    <th>Post-Likelihood</th>
                                    <th>Post-Severity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {extracted.Triplets_not_found_in_OTGDb.map((triple, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{triple.Activity}</td>
                                            <td>{triple.Risk}</td>
                                            <td>{triple.Mitigation}</td>
                                            <td>{triple.Risk_Factors ? triple.Risk_Factors.Pre_Mitigation ? triple.Risk_Factors.Pre_Mitigation.Likelihood : 'N/A' : 'N/A'}</td>
                                            <td>{triple.Risk_Factors ? triple.Risk_Factors.Pre_Mitigation ? triple.Risk_Factors.Pre_Mitigation.Severity : 'N/A' : 'N/A'}</td>
                                            <td>{triple.Risk_Factors ? triple.Risk_Factors.Post_Mitigation ? triple.Risk_Factors.Post_Mitigation.Likelihood : 'N/A' : 'N/A'}</td>
                                            <td>{triple.Risk_Factors ? triple.Risk_Factors.Post_Mitigation ? triple.Risk_Factors.Post_Mitigation.Severity : 'N/A' : 'N/A'}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </Tab >
            )
        } else {
            return <Tab title="Extracted" disabled></Tab>
        }
    }

    function displayRecommendations () {
        if (recommendations) {
            return (
                <Tab title="Recommendations" eventKey="recommendations">
                    <div className="TabContent">
                        <table>
                            <thead>
                                <tr>
                                    <th>Activity</th>
                                    <th>Risk</th>
                                    <th>Mitigation</th>
                                    <th>Cosine Score</th>
                                    <th>OTG Lookup Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                {recommendations.map((rec, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{rec.Activity}</td>
                                            <td>{rec.Risk}</td>
                                            <td>{rec.Mitigation}</td>
                                            <td>{rec.Cosine_MatchScore}</td>
                                            <td>{rec.OTG_Lookup_MatchScore}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </Tab>
            )
        } else {
            return (
                <Tab title="Recommendations" disabled></Tab>
            )
        }
    }

    function displaySafetyBriefings () {
        if (safetyBriefings) {
            return (
                <Tab title="Safety Briefings" eventKey="safetyBriefings">
                    <div className="TabContent">
                        <ul>
                            {safetyBriefings.map((briefing, index) => {
                                return <li key={index}>{briefing.Title}</li>
                            })}
                        </ul>

                    </div>
                </Tab>
            )
        } else {
            return (<Tab title="Safety Briefings" disabled></Tab>)
        }
    }

    function displayResponses () {
        return (
            <Tabs>
                {displayStatus()}
                {displayRecommendations()}
                {displaySafetyBriefings()}
                {displayExtracted()}
            </Tabs >
        )
    }

    return (
        <Container>
            <Row>
                <div className="Header">
                    <h1>Test Documents</h1>
                    <DropdownButton title={environment} onSelect={e => { setEnvironment(e) }} className="EnvironmentSelect">
                        <Dropdown.Item eventKey="Staging">Staging</Dropdown.Item>
                        <Dropdown.Item eventKey="Production">Production</Dropdown.Item>
                    </DropdownButton>
                </div>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <div className="FileUpload">
                            <Form>
                                <Form.Control type="file"
                                    id="ra-upload"
                                    label="Risk Assessment"
                                    onChange={onFileUpload}
                                />
                            </Form>
                            <Button onClick={uploadRiskAssessment}>Upload</Button>
                        </div>
                    </Row>
                    <Row style={{ paddingTop: '5px' }}>
                        <p>Client Id</p>
                        <Form.Control type="text" placeholder="Client Id" onChange={(e) => { setClientId(e.target.value) }} style={{ width: '100px', marginLeft: '10px' }} />
                        <ViewAllClientsButton show={showClients} setShow={setShowClients} />
                    </Row>
                </Col>
                <Col>
                    <div className="JobIdInputHolder">
                        <Form className="JobIDInput">
                            <Form.Label>Check Job ID</Form.Label>
                            <Form.Control type="text" placeholder="Job Id" onChange={(e) => { setJobId(e.target.value) }}></Form.Control>
                        </Form>
                        <Button className="CheckJobIDButton" onClick={onCheckStatus}>Check</Button>
                    </div>
                </Col>
            </Row>
            <Row>
                {displayJobId()}
            </Row>
            <Row>
                {displayResponses()}
            </Row>
        </Container>
    )
}

export default TestDocuments
