import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'

import { useKeycloak } from '@react-keycloak/web'

function TripleSearch (props) {
    const { keycloak } = useKeycloak()

    function searchForTriple () {
        console.log('Searching for triple')
        fetchTriple()
    }

    function buildElasticQuery () {
        const query = []

        if (props.activity) {
            query.push({ match: { Activity: props.activity } })
        }

        if (props.risk) {
            query.push({ match: { Risk: props.risk } })
        }

        if (props.mitigation) {
            query.push({ match: { Mitigation: props.mitigation } })
        }

        return JSON.stringify({ from: props.startPoint, size: 10, query: { bool: { must: query } } })
    }

    function buildSemanticQuery () {
        const query = {}

        if (props.activity) {
            query.Activity = props.activity
        }

        if (props.risk) {
            query.Risk = props.risk
        }

        if (props.mitigation) {
            query.Mitigation = props.mitigation
        }

        return JSON.stringify(query)
    }

    function getElasticURL () {
        if (props.db === 'Live Database') {
            return 'https://api.intuety.io/aurora/api/elasticdb/otg/lookup'
        } else if (props.db === 'Staging Database') {
            return 'https://api.staging.intuety.io/aurora/api/elasticdb/otg/lookup'
        }
    }

    function getSemanticURL () {
        if (props.db === 'Live Database') {
            return 'https://api.intuety.io/aurora/api/elasticdb/otg/check-triple'
        } else if (props.db === 'Staging Database') {
            return 'https://api.staging.intuety.io/aurora/api/elasticdb/otg/check-triple'
        }
    }

    function fetchTriple () {
        if (props.useSemanticLookup) {
            const options = {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Auth-Token': props.db === 'Staging Database' ? keycloak.tokenParsed?.api_token_staging : keycloak.tokenParsed?.api_token
                },
                body: buildSemanticQuery()
            }
            fetch(getSemanticURL(), options).then(response => {
                console.log(`status code received: ${response.status}`)
                const res = response.json()
                return res
            }).then((data) => {
                console.log(data)
                const list = data.map(item => {
                    item._id = item.id
                    return item
                })
                props.setReturnedInfo(list)
            }).catch(error => {
                console.log(`Error received: ${error}`)
            })
        } else {
            const options = {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Auth-Token': props.db === 'Staging Database' ? keycloak.tokenParsed?.api_token_staging : keycloak.tokenParsed?.api_token
                },
                body: buildElasticQuery()
            }
            fetch(getElasticURL(), options).then(response => {
                console.log(`status code received: ${response.status}`)
                const res = response.json()
                return res
            }).then((data) => {
                props.setElasticTotal(data.hits.total)
                const list = data.hits.hits.map(item => {
                    const triple = item._source
                    triple._id = item._id
                    return triple
                })
                props.setReturnedInfo(list)
            }).catch(error => {
                console.log(`Error received: ${error}`)
            })
        }
    }

    useEffect(() => {
        if (props.enterHit) {
            searchForTriple(props)
            props.setEnterHit(false)
        }

        if (props.newPage) {
            searchForTriple(props)
            props.setNewPage(false)
        }
    }, [props.enterHit, props.newPage])

    return (
        <Button variant='outline-success' onClick={searchForTriple}> Search </Button>
    )
}

export default TripleSearch
