import { useKeycloak } from '@react-keycloak/web'
import React, { useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { getAllClients } from '../utils'

function EditProhibitedEquipmentModal (props) {
    const [name, setName] = useState('')
    const [activities, setActivities] = useState([])
    const [clientIDs, setClientIDs] = useState([])
    const [clients, setClients] = useState([])
    const { keycloak } = useKeycloak()

    const handleClose = () => props.setShow(false)

    const onNameKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
        }

        setName(event.target.value)
    }

    const onActivityKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            setActivities([...activities, event.target.value])
            event.target.value = ''
        }
    }

    const onClientKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()

            if (parseInt(event.target.value)) {
                setClientIDs([...clientIDs, parseInt(event.target.value)])
                event.target.value = ''
            } else {
                alert('Client ID must be an integer')
            }
        }
    }

    const onRemoveActivity = (pos) => {
        const tmp = activities
        tmp.splice(pos, 1)

        if (tmp.length === 0) {
            setActivities([])
        } else {
            setActivities([...tmp])
        }
    }

    const onRemoveClient = (pos) => {
        const tmp = clientIDs
        tmp.splice(pos, 1)

        if (tmp.length === 0) {
            setClientIDs([])
        } else {
            setClientIDs([...tmp])
        }
    }

    function getURL () {
        if (props.db === 'Live Database') {
            return `https://api.intuety.io/aurora/api/prohibited-equipment/${props.item._id}`
        } else if (props.db === 'Staging Database') {
            return `https://api.staging.intuety.io/aurora/api/prohibited-equipment/${props.item._id}`
        }
    }

    function getBody () {
        const body = {
            name: name,
            activities: activities
        }

        if (props.item._source.client_ids || clientIDs.length > 0) {
            body.clientIDs = clientIDs
        }

        return JSON.stringify(body)
    }

    const onSubmit = async () => {
        try {
            const options = {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Auth-Token': props.db === 'Live Database' ? keycloak.tokenParsed?.api_token : keycloak.tokenParsed?.api_token_staging
                },
                body: getBody()
            }

            const response = await fetch(getURL(), options)

            if (response.status === 200) {
                alert('Updated item successfully')
            } else {
                alert('Problem editing item')
            }

            handleClose()
            setTimeout(props.updateSearch(), 20)
        } catch {
            alert('Unexpected problem editing item')
        }
    }

    useEffect(() => {
        if (props.item) {
            if (props.item._source.associated_activities.length > 0) {
                setActivities(props.item._source.associated_activities)
            }

            if (props.item._source.client_ids) {
                setClientIDs(props.item._source.client_ids)
            }

            setName(props.item._source.name)
            document.getElementById('name-input').value = props.item._source.name
        }
    }, [props.show])

    useEffect(() => {
        const fetchClients = async () => {
            const res = await getAllClients()
            setClients(res)
        }

        fetchClients()
    })

    return (
        <Modal show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Prohibited Equipment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter name" onChange={onNameKeyDown} id="name-input" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Associated Activities</Form.Label>
                        <Form.Control type="text" placeholder="Enter activities" onKeyPress={onActivityKeyDown} />
                        {activities.map((activity, pos) => <p key={pos}> {activity}  <Button onClick={() => { onRemoveActivity(pos) }}>Delete</Button></p>)}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Client ID</Form.Label>
                        <Form.Control type="text" placeholder="Enter client ids" onKeyPress={onClientKeyDown} />
                        {clientIDs.map((id, pos) => <p key={pos}>{id} <i>{clients.find(client => client.clientId === id).clientName}</i> <Button onClick={() => { onRemoveClient(pos) }}>Delete</Button></p>)}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={onSubmit} disabled={!(name && activities.length > 0)}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditProhibitedEquipmentModal
